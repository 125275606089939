.outer-container {
    width: 80%;
    margin: auto;
    margin-bottom: 3rem;
}


@media screen and (min-width: 1200px) {

    .outer-container {
        width: 70%;
    }

}

@media screen and (min-width: 1550px) {

    .outer-container {
        width: 60%;
    }

}