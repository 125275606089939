/**************** GLOBAL ****************/

* {
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

body {
    margin: 0;
}

/**************** NAVBAR ****************/

.nav {
    background-color: #FFFFFF;
    color: #000000;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    position: relative;
    padding: 0 1rem;
    margin-bottom: 3rem;
    margin-top: 2rem;
}

.nav img {
    width: 175px;
}

.nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
}

.nav a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 1.25rem 2rem;
}

.nav-link {
    border-right: 1px solid #CA9031;
    margin: 0;
}

.nav-link:last-child {
    border-right: none;
}

.site-title {
    font-size: 2rem;
}

.nav li.active {
    background-color: #F9E4BB;
    color: #000000;
}

.nav li:hover {
    background-color: #f6f6f6;
    color: #1B6B53;
}

.navigation-menu {
    margin-left: auto;
}

.hamburger {
    border: 0;
    height: 20px;
    width: 20px;
    padding: 0.5rem;
    border-radius: 15px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    display: none;
}

.hamburger img {
    height: 50px;
    width: 50px;
    border-radius: 15px;
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
}

.site-logo {
    width: 175px;
}

@media screen and (max-width: 768px) {
    .hamburger {
        display: block;
    }

    .navigation-menu ul {
        display: none;
    }

    .navigation-menu.expanded ul {
        display: block;
    }

    .navigation-menu ul {
        position: absolute;
        top: 70px;
        left: 10%;
        flex-direction: column;
        width: 80%;
        background-color: white;
    }

    .nav a {
        color: inherit;
        text-decoration: none;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 1.25rem 2rem;
    }

    .nav-link {
        border: 1px solid #CA9031;
        border-bottom: none;
        color: black;
        width: 100%;
        padding: 0;
    }

    .nav-link:last-child {
        border: 1px solid #CA9031;
        margin: 0;
    }

    .navigation-menu li {
        display: flex;
        justify-content: center;
    }

    .navigation-menu li:hover {
        background-color: #EEEEEE;
    }

}

/**************** FOOTER ****************/

.footer {
    border-top: 3px solid #1B6B53;
    border-bottom: 3px solid #1B6B53;
    background-color:#F9E4BB;
    display: flex;
    gap: 6rem;
    justify-content: center;
    padding-bottom: 3rem;
    padding-top: 2rem;
}

.footer-links ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.footer-links li {
    padding: 0.25rem;  
}

.footer-links a {
    text-decoration: none;
    color: inherit;
}

.footer-social ul{
    padding: 0;
    margin: 0;
    list-style: none;
}

.footer-social li {
    padding: 0.25rem;
}

.footer-social a {
    text-decoration: none;
    color: inherit;
}

.copyright-div {
    background-color: #1B6B53;
    text-align: center;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.copyright-div p {
    margin: 0;
    color: #AAAAAA;
    font-size: 1rem;
    padding: 0 0.5rem;
}

@media screen and (max-width: 768px) {
    .footer {
        flex-direction: column;
        gap: 2rem;
        align-items: center;
    }

    .footer-content {
        width: 50%;
    }

    .footer-links {
        width: 50%;
    }

    .footer-social {
        width: 50%;
    }
}

@media screen and (max-width: 500px) {
    .footer {
        margin-left: -2rem;
    }
}

/**************** HOME ****************/

.home-welcome-text {
    text-align: center;
    margin-top: 3rem;
    margin-bottom: 2rem;
}

.home-welcome-content {
    text-align: center;
    margin-bottom: 3rem;
    font-size: 1.25rem;
}

.home-studio-img-div {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}

.home-studio-img-div picture,
.home-studio-img-div img {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.home-hr {
    color: #CA9031;
    border-top: 2px solid;
    margin-top: 3rem;
    margin-bottom: 3rem;
    margin-right: 10%;
    margin-left: 10%;
}

.home-hr:first-of-type {
    margin-top: 8rem;
}

.home-hr:last-of-type {
    margin-bottom: 8rem;
}

.home-motto-div {
    text-align: center;
}

.home-motto-div > h2 {
    font-family: 'Dancing Script', cursive;
    font-size: 2.5rem;
}

.home-class-title {
    text-align: center;
    margin-bottom: 4rem;
    font-size: 2.5rem;
}

.home-class-container {
    display: flex;
}

.home-class-container > div:not(:last-child) {
    margin-right: 50px;
}

.home-class-container > div {
    text-align: center;
}

.home-class-img-div img {
    width: 100%;
}

.home-class-text {
    font-size: 1.25rem;
    margin-top: 10px;
    margin-bottom: 30px;
}

.home-bottom-div{
    margin: 6rem auto;
    text-align: center;
}

.home-bottom-div-link {
    background-color: #1B6B53;
    color: #F9E4BB;
    padding: 1.5rem;
    border-width: 0px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0.25rem #DBDBDB;
    font-size: 18px;
    text-decoration: none;
}

.home-bottom-div-link:hover {
    background-color: #F9E4BB;
    color: #1B6B53;
}

.home-bottom-div > p {
    font-size: 1.25rem;
    margin-bottom: 2.5rem;
}

.home-hr-2 {
    border-top: 2px solid;
    color: #CA9031;
    margin-top: 80px;
    margin-bottom: 50px;
    margin-right: 10%;
    margin-left: 10%;
}

.home-address-div {
    text-align: center;
}

.home-address-div > h2 {
    font-size: 2rem;
}

.home-address-div > p {
    font-size: 1.25rem;
}

.home-location-img-div {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.home-location-img-div picture,
.home-location-img-div img {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

@media screen and (max-width: 768px) {
    
    .home-class-container {
        flex-direction: column;
        justify-content: center;
    }

    .home-class-container > div:not(:last-child) {
        margin-right: 0;
    }

    .home-class-img-div img {
        width: 80%;
    }

    .home-class-text {
        margin-bottom: 4rem;
    }
    
    .home-bottom-div {
        margin-top: 0;
    }

}

@media screen and (min-width: 769px) {

    .home-class-container {
        flex-direction: column;
    }

    .home-class-subcontainer {
        display: flex;
        margin-right: 0px;
    }

    .home-class-container > div:not(:last-child) {
        margin-right: 0px;
    }

    .home-class-img-div img {
        width: 90%;
    }

}

@media screen and (min-width: 1200px) {

    /* .home-class-container {
        flex-direction: row;
    } */
    .home-class-img-div img {
        width: 80%;
    }

}

/**************** ABOUT ****************/

.about-sub-div {
    display: flex;
    margin-top: 5rem;
}

.about-img-div {
    width: 45%;
    margin-right: 5%;
}

.about-img {
    width: 100%;
}

.about-text-div {
    width: 45%;
}

.about-text-div > h2 {
    margin-top: 0;
    font-size: 2rem;
}

.about-text-div > p {
    font-size: 1.2rem;
}

@media screen and (max-width: 768px) {

    .about-sub-div {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .about-img-div {
        width: 80%;
        margin-bottom: 2rem;
    }

    .about-text-div {
        width: 80%;
    }

}

@media screen and (min-width: 1100px) {

    .about-sub-div {
        justify-content: center;
    }

    .about-img-div {
        width: 30%;
    }
}

@media screen and (min-width: 1200px) {

    .about-sub-div {
        justify-content: center;
    }

    .about-img-div {
        width: 30%;
    }

}

/**************** CLASSES ****************/

.class-calendar {
    width: 100%;
}

.class-intro-text {
    font-size: 1.25rem;
    margin-top: 3rem;
}

.class-card-sub-div {
    display: flex;
    margin-top: 5rem;
}

.class-card-img-div {
    width: 45%;
    margin-right: 5%;
}

.class-card-img {
    width: 100%;
}

.class-card-text-div {
    width: 45%;
}

.class-card-text-div > h2 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.8rem;
}

.class-card-text-div > h3 {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;
}

.class-card-text-div > p {
    font-size: 1.2rem;
}

.class-bottom-div {
    text-align: center;
    margin-bottom: 3rem;
}

.class-bottom-div > h2 {
    margin-top: 3rem;
    margin-bottom: 2rem;
}

.class-bottom-div-link {
    background-color: #1B6B53;
    color: #F9E4BB;
    padding: 1rem 2rem;
    border-width: 0px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0.25rem #DBDBDB;
    font-size: 18px;
    text-decoration: none;
}

.class-bottom-div-link:hover {
    background-color: #F9E4BB;
    color: #1B6B53;
}

@media screen and (max-width: 500px) {

    .class-card-sub-div {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .class-card-img-div {
        width: 90%;
        margin-bottom: 2rem;
    }

    .class-card-text-div {
        width: 90%;
    }

}

@media screen and (min-width: 501px) and (max-width: 768px) {

    .class-card-sub-div {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .class-card-img-div {
        width: 80%;
        margin-bottom: 2rem;
    }

    .class-card-text-div {
        width: 80%;
    }

}

@media screen and (min-width: 1100px) {

    .class-card-sub-div {
        justify-content: center;
    }

    .class-card-img-div {
        width: 30%;
    }
}

@media screen and (min-width: 1200px) {

    .class-card-sub-div {
        justify-content: center;
    }

    .class-card-img-div {
        width: 30%;
    }

}

/**************** GET STARTED ****************/

.get-started-outer p {
    font-size: 1.25rem;
}

.get-started-outer ul {
    font-size: 1.25rem;
}

.get-started-contact-div {
    border-style: solid;
    border-width: 0px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0.25rem #DBDBDB;
    padding: 2rem;
    width: 60%;
    margin: 0 auto;
    margin-top: 2rem;
}

.get-started-contact-div input:not(:last-child) {
    width: 100%;
    display: block;
    margin-bottom: 1rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    border-style: solid;
    border-width: 1px;
    border-color: #AAAAAA;
    border-radius: 5px;
}

.get-started-contact-div textarea {
    width: 100%;
    display: block;
    margin-bottom: 1rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    border-style: solid;
    border-width: 1px;
    border-color: #AAAAAA;
    border-radius: 5px;
    resize: none;
}

.get-started-required-field:after {
    content: "*";
    color: red;
}

.get-started-submit {
    background-color: #1B6B53;
    color: #F9E4BB;
    padding: 1.5rem;
    border-width: 0px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0.25rem #DBDBDB;
    font-size: 18px;
    width: 100%;
}

.get-started-submit:hover {
    background-color: #F9E4BB;
    color: #1B6B53;
}

.get-started-info-div > h2 {
    margin-top: 4rem;
    margin-bottom: 3rem;
    text-align: center;
    font-size: 1.75rem;
}

.get-started-info-div > h3 {
    font-size: 1.5rem;
}

.get-started-info-div > p {
    margin-top: 3rem;
    margin-bottom: 2rem;
    font-size: 1.25rem;
}

.slider-wrapper {
    width: 100%;
}

.slick-prev,
.slick-next {
    opacity: 1;
    background: rgb(202, 194, 194) !important;
    width: 30px !important;
    height: 30px !important;
}

.slick-slide img {
    width: 100%;
    padding: 10px;
}

@media screen and (max-width: 768px) {

    .get-started-contact-div {
        width: 100%;
    }

}